export default {
  name: 'AnalyticsPostCardFooter',

  props: {
    metrics: {
      default: null,
    },
    /**
     * Data to Show should be (In order):
     * publishDate, time, totalEngagements, peopleReached, impressions, totalClicks, engagementRate, likes, comments, saved, shares,
     */

    dataToShow: {
      required: true,
      type: Array,
    },
  },

  computed: {
    avatar() {
      if (this.dataToShow.includes('avatar')) {
        const avatarUrl =
          this.metrics?.avatar ??
          this.metrics?.avatarUrl ??
          this.metrics?.metrics?.avatar ??
          this.metrics?.metrics?.avatarUrl;

        return avatarUrl;
      }
    },
  },

  methods: {
    getValue(name) {
      const value = this.metrics[name] ?? 0;

      if (value) {
        return this.$filters.formatNumber(value);
      }

      return 0;
    },
  },
};
